//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dateFunc from "./components/dateFunc";
import moment, { months } from "moment";
import EventCard from "./components/eventCard.vue";
import vHeader from "./components/header.vue";

export default {
  props: {
    events: {
      type: Array,
      default: () => []
    },
    locale: {
      type: String,
      default: "en"
    },
    selectStatus: {
      type: Number,
      default: 2
    },
    firstDay: {
      type: Number | String,
      validator(val) {
        let res = parseInt(val);
        return res >= 0 && res <= 7;
      },
      default: 0
    },
    initialDayTime: {
      type: Number,
      default: 0
    },
    deleteGroupDate: {
      type: Array,
      default: () => []
    },
    clickType: {
      type: String,
      default: "checkbox" // 'checkbox': 多选显示勾勾，原本的逻辑; 'radio': 新增的，单选，不显示勾勾，不执行多选逻辑
    }
  },
  components: {
    "event-card": EventCard,
    "fc-header": vHeader
  },
  watch: {
    deleteGroupDate(val) {
      this.saveDateList = val;
      this.getCalendar();
    },
    initialDayTime(val) {
      for (let time in this.formTime) {
        this.formTime[time] = this.eventBox;
      }
    }
  },

  data() {
    return {
      currentMonth: moment().startOf("month"),
      isLismit: true,
      eventLimit: 3,
      eventBox: [],
      formTime: {},
      timeKey: "",
      showMore: false,
      showList: false,
      morePos: {
        top: 0,
        left: 0
      },
      selectDay: {},
      saveDateList: [],
      btnStyle: true
    };
  },
  computed: {
    currentDates() {
      return this.getCalendar();
    }
  },

  methods: {
    setEventBox(index, zindex) {
      // console.log(index,zindex)
    },
    emitChangeMonth(firstDayOfMonth) {
      let nowDate = new Date(moment().format("YYYY-MM-01 00:00:00")).valueOf();
      let clickMonth = new Date(firstDayOfMonth).valueOf();
      if (clickMonth >= nowDate) {
        this.btnStyle = false;
        this.currentMonth = firstDayOfMonth;
        let start = dateFunc.getMonthViewStartDate(
          firstDayOfMonth,
          this.firstDay
        );
        let end = dateFunc.getMonthViewEndDate(firstDayOfMonth, this.firstDay);
        this.$emit("changeMonth", start, end, firstDayOfMonth);
        clickMonth === nowDate ? (this.btnStyle = true) : "";
      }
      // this.saveTime(firstDayOfMonth._d)
    },
    saveTime(time) {
      let date = time;
      let key = (this.timeKey =
        date.getFullYear() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getDate());
      let formTime = {};
      formTime = this.formTime;
      let arr = Object.keys(formTime);
      if (arr.length > 0) {
        if (arr.indexOf(key) > -1) {
          return true;
        } else {
          formTime[key] = JSON.parse(JSON.stringify(this.eventBox));
        }
      } else {
        formTime[key] = JSON.parse(JSON.stringify(this.eventBox));
      }
    },
    moreTitle(date) {
      if (!date) return "";
      return moment(date).format("ll");
    },
    getCalendar() {
      // calculate 2d-array of each month
      let monthViewStartDate = dateFunc.getMonthViewStartDate(
        this.currentMonth,
        this.firstDay
      );
      let calendar = [];
      for (let perWeek = 0; perWeek < 6; perWeek++) {
        let week = [];

        for (let perDay = 0; perDay < 7; perDay++) {
          let isShow = false;
          this.saveDateList.map(saveDate => {
            if (saveDate === moment(monthViewStartDate).format("YYYY-MM-DD")) {
              isShow = true;
            }
          });

          week.push({
            monthDay: monthViewStartDate.date(),
            isToday: monthViewStartDate.isSame(moment(), "day"),
            isCurMonth: monthViewStartDate.isSame(this.currentMonth, "month"),
            weekDay: perDay,
            date: moment(monthViewStartDate),
            events: this.filterGroup(this.events, monthViewStartDate),
            isShow: isShow
          });
          monthViewStartDate.add(1, "day");
        }
        calendar.push(week);
      }
      return calendar;
    },
    filterGroup(list, date) {
      let events = {};
      list.map(item => {
        if (
          moment(item.teamTime).format("YYYY-MM-DD") ===
          date.format("YYYY-MM-DD")
        ) {
          events = {
            emptySeat: item.emptySeat,
            price: item.balanceAdultPrice,
            holdSeat: item.holdSeat,
            date: moment(item.teamTime).format("YYYY-MM-DD"),
            peopleNum: 1,
            totalAll: 0,
            specialStatus: item.specialStatus // 特价出售状态:0为起售,1为停售
          };
        }
      });
      return events;
    },
    slotEvents(date) {
      // find all events start from this date
      // let cellIndexArr = [];
      let thisDayEvents = this.events.filter(day => {
        let st = moment(day.start);
        let ed = moment(day.end ? day.end : st);

        return date.isBetween(st, ed, null, "[]");
      });

      // sort by duration
      thisDayEvents.sort((a, b) => {
        if (!a.cellIndex) return 1;
        if (!b.cellIndex) return -1;
        return a.cellIndex - b.cellIndex;
      });

      // mark cellIndex and place holder
      for (let i = 0; i < thisDayEvents.length; i++) {
        thisDayEvents[i].cellIndex = thisDayEvents[i].cellIndex || i + 1;
        thisDayEvents[i].isShow = true;
        if (thisDayEvents[i].cellIndex === i + 1 || i > 2) continue;
        thisDayEvents.splice(i, 0, {
          title: "holder",
          cellIndex: i + 1,
          start: date.format(),
          end: date.format(),
          isShow: false
        });
      }

      return thisDayEvents;
    },
    selectThisDay(day, jsEvent) {
      this.selectDay = day;
      this.showMore = true;
      this.morePos = this.computePos(event.target);
      this.morePos.top -= 100;
      let events = day.events.filter(item => {
        return item.isShow === true;
      });
      this.$emit("moreClick", day.date, events, jsEvent);
    },
    computePos(target) {
      let eventRect = target.getBoundingClientRect();
      let pageRect = this.$refs.dates.getBoundingClientRect();
      return {
        left: eventRect.left - pageRect.left,
        top: eventRect.top + eventRect.height - pageRect.top
      };
    },
    dayClick(day, jsEvent, data, index, zindex, monthShow) {
      // console.log(day)
      if (Object.keys(data.events).length === 0) return;

      if (this.clickType === "checkbox") {
        // 多选清空才改变
        data.isShow = !data.isShow;
        this.$forceUpdate();
        if (data.isShow) {
          this.saveDateList.push(day.format("YYYY-MM-DD"));
        } else {
          let saveArr = [];
          saveArr = this.saveDateList.filter(item => {
            return item !== day.format("YYYY-MM-DD");
          });
          this.saveDateList = saveArr;
        }
      }

      // console.log(day.format('YYYY-MM-DD'))
      this.$emit("dayClick", data, jsEvent);
    },
    eventClick(event, jsEvent) {
      if (!event.isShow) return;
      jsEvent.stopPropagation();
      let pos = this.computePos(jsEvent.target);
      this.$emit("eventClick", event, jsEvent, pos);
    }
  },
  filters: {
    localeWeekDay(weekday, firstDay, locale) {
      firstDay = parseInt(firstDay);
      const localMoment = moment().locale(locale);
      let week = localMoment
        .localeData()
        .weekdaysShort()
        [(weekday + firstDay) % 7].substring(1);
      return week;
    }
  }
};
