import FullCalendar from "@/page/distributor/component/FullCalendar";
import getDetail from "@/lib/data-service/default/web_common_productDetail";
import reservaOrder from "@/lib/data-service/default/web_common_tourCutOrder_reserveOrder";

export default {
  data() {
    return {
      navList: ["首页", "出境产品", "产品详情", "产品预定"],
      navLink: "1",
      chooseGroupList: [],
      detail: {},
      PreferentialInfo: [],
      settlement: {
        peopleNum: 0,
        totalAll: 0,
        cutNumAll: 0,
        cutNum: 0
      },
      tourismTeamResult: [],
      deleteGroupDate: [],
      orderNo: "",
      loading: false
    };
  },
  created() {
    this.orderNo = this.$route.query.productNo;
    this._getDetail();
  },

  methods: {
    changeMonth(start, end, now) {
      // console.log(this.$moment(now).format('YYYY-MM-DD'))
    },
    lookFile(val) {
      window.open(val.fileUrl);
    },
    countSum(val) {
      let infoMoney = this.settlement;
      infoMoney.totalAll = 0;
      infoMoney.peopleNum = 0;
      infoMoney.cutNum = 0;
      infoMoney.cutNumAll = 0;
      val.map(item => {
        infoMoney.totalAll += item.events.totalAll;
        infoMoney.peopleNum += item.events.peopleNum;
      });
      this.PreferentialInfo.map(item => {
        if (infoMoney.peopleNum >= item.minNum && (infoMoney.peopleNum <= item.maxNum || item.maxNum === -1)) {
          infoMoney.cutNum = item.discountsPrice;
          infoMoney.cutNumAll = infoMoney.peopleNum * item.discountsPrice;
          // infoMoney.totalAll = infoMoney.totalAll - infoMoney.cutNumAll;
        }
        // else{
        //   infoMoney.cutNumAll = 0
        // }
      });
    },
    submit() {
      if (this.chooseGroupList.length == 0)
        return this.$message({ type: "warning", message: "请选择团期" });
      if (this.chooseGroupList.length < 2) return this.$message({ type: "warning", message: "请选择两个以上团期" })
      let infoMoney = this.settlement;
      let passengersArray = [];
      this.chooseGroupList.map(item => {
        let passengers = {
          num: item.events.peopleNum,
          team: item.events.date,
          weekNum: item.weekDay
        };
        passengersArray.push(passengers);
      });
      let data = {
        productNo: this.orderNo,
        reserveMsgs: passengersArray
      };
      this.loading = true
      reservaOrder(data).then(res => {
        this.loading = false
        res.code === this.SUCCESS_CODE
          ? this.$router.push({
            name: "distributor-tour-front-travel-order-success",
            query: { orderNo: res.cutOrderNo }
          })
          : "";
      }, error => {
        this.loading = false
      });
    },
    dayClick(date, val) {
      if (date.isShow) {
        this.chooseGroupList.push(date);
      } else {
        this.chooseGroupList.map((item, index) => {
          if (item.events.date === date.events.date) {
            this.chooseGroupList.splice(index, 1);
          }
        });
      }
      setTimeout(() => {
        this.countSum(this.chooseGroupList);
      }, 100);
    },
    deletePeople(val) {
      this.chooseGroupList.map((item, index) => {
        if (item.events.date === val.events.date) {
          this.chooseGroupList.splice(index, 1);
        }
      });
      this.countSum(this.chooseGroupList);
      let arrDate = [];
      this.chooseGroupList.map(item => {
        arrDate.push(item.events.date);
      });
      this.deleteGroupDate = arrDate;
    },
    calculate() {
      setTimeout(() => {
        this.countSum(this.chooseGroupList);
      }, 100);
    },
    _getDetail() {
      getDetail({ productNo: this.orderNo }).then(res => {
        res.code == this.SUCCESS_CODE
          ? ((this.PreferentialInfo = res.cutDiscounts),
            (this.tourismTeamResult = res.tourismTeamResult),
            (this.detail = res))
          : "";
      });
    }
  },
  components: { FullCalendar }
};
