
const __request = require(`./__request/__request_contentType_json`)

/**
 * 提交切位订单
 */
export default function reservaOrder(val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/common/tourCutOrder/reserveOrder"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
